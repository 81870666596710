export const hexToRgb = (hex) => {
  if (!hex) return ''
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.slice(0,7))
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export const truncateText = (text, chars) => text ? text.replace(/<\/?[^>]+>/ig, ' ').substring(0, chars || 200).trim() + '...' : ''

export const reducedString = (text) => text ? text.replaceAll(" ","").replace(/[\W_]+/g,"").toLowerCase() : ''
