<template>
  <div :style="cssVars">
    <v-layout>
      <v-main id="pages" :style="'color:' + textColour + '; background-color:' + backgroundColour">
        <v-img :src="boxOffice && boxOffice.backgroundImage" min-height="100%" :class="{
          'v-image--sm': this.$vuetify.breakpoint.smAndDown,
          'v-image--md': this.$vuetify.breakpoint.mdAndDown
        }">
          <Toolbar :boxOffice="boxOffice"/>
          <div align ="right">
            <checkoutButton @click="checkout=true" :order="order"/>
          </div>
          <div class="mb-12 pb-12">
            <Loading v-if="!boxOffice" />
            <div v-else-if="boxOffice">
              <div v-if="boxOffice.boxOfficeImage" class="banner">
                <img :src="getCloudinaryImage({url: boxOffice.boxOfficeImage, width: 1200})">
              </div>
              <div class="inner inner--narrow">
                <div class="logo">
                  <img v-if="boxOffice.logoImage" :src="boxOffice.logoImage" style="display:table" class="mx-auto">
                  <pages-heading v-else class="text-center mb-5">{{boxOffice.name}}</pages-heading>
                  <div class="mt-4">
                    <span v-html="boxOffice.description"/>
                  </div>
                </div>
                <div class="events">
                  <!--queue v-if="queued || !loaded" :loaded="loaded" @queued="queueUpdate"/-->
                  <div v-if="loaded && !events.length"><v-card-title>Sorry, no published events</v-card-title></div>
                  <div v-else-if="loaded">
                    <h2 class="mb-4">Upcoming events</h2>
                    <div v-for="(event, i) in events" :key="i"> 
                      <div class="image-card image-card--event image-card--outline mb-4">
                        <div class="image-card__inner">
                          <!-- <div v-if="!event.hideDatesOnBoxOfficePage" class="image-card__image">
                            <v-icon>mdi-calendar-blank</v-icon>
                            <span class="timestamp timestamp--year"><b>{{ utils.timezoneDateToShortString(event.eventTime.start, event.eventTime.timezone, 'YYYY') }}</b></span>
                            <span class="timestamp"><b>{{ utils.timezoneDateToShortString(event.eventTime.start, event.eventTime.timezone, 'DD MMM') }}</b></span>
                          </div> -->
                          <div class="image-card__content pb-4">
                            <h3 v-if="event.eventTime.start || event.eventTime.end">
                              <v-icon class="mt-n1" :color="textColour">mdi-clock-time-four</v-icon>
                              <span style="font-size:14px;">
                                {{utils.timezoneDateToShortString(event.eventTime.start, event.eventTime.timezone)}} to {{utils.timezoneDateToShortString(event.eventTime.end, event.eventTime.timezone, utils.sameDay(event.eventTime.start, event.eventTime.end) ? 'h:mmA' : null)}}
                              </span>
                            </h3>
                            <h3 class="z-1">{{ event.name }}</h3>
                            <div v-if="event.summary">
                              <p class="mt-3">{{event.summary}}</p>
                            </div>
                          </div>
                          <div class="actions">
                            <div class="actions__cta ml-auto">
                              <div>
                                <Button @click="buyTickets(event)">Tickets <v-icon>mdi-chevron-right</v-icon></Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="!event.published" class="text-center image-card__bar pb-1 pt-1">
                          <span class="grey">This event has not been published - only you can see it</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <v-main>
                <v-container>
                <v-row align="center" justify="center">
                    <v-col class="text-center"><pages-heading>404 - Not Found</pages-heading>
                    </v-col>
                  </v-row>
                </v-container>
              </v-main>
            </div>
          </div>
          <contact :boxOffice="boxOffice"/>
        </v-img>
      </v-main>
    </v-layout>
    <checkout v-if="checkout" :boxOffice="boxOffice" @closed="checkout=false" @closedConfirmed="checkout=false"/>
  </div>
</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js'
import Store from '@/services/Store.js';
import { hexToRgb } from '@/helpers/common'
import { getCloudinaryImage } from '@/helpers/cloudinary'

export default {
  components: {
    Checkout: () => import('./Checkout'),
    Contact: () => import('./Contact'),
    PagesHeading: () => import('../pages/components/Heading'),
    EventTimeDisplay: () => import('../dashboard/components/EventTimeDisplay'),
    CheckoutButton: () => import('./CheckoutButton'),
    Button: () => import('./Button'),
    Toolbar: () => import('./Toolbar'),
    Loading: () => import('./Loading'),
  },  

  metaInfo() {
      return this.meta;
  },

  data () {
    return {
      getCloudinaryImage,
      meta:  {
        title: "Openstage"
      },
      textColour: null,
      backgroundColour: null,
      boxOffice: {},
      events: [],
      eventTimes: {},
      backgroundImage: {},
      backgroundImageUrl: "",
      order: Store.order,
      maxImageWidth: 2400,
      checkout: false,
      loaded: false,
      queued: true,
    }
  },
  
  async created() {
    this.boxOffice.path = this.$route.params.boxOfficePath;
    this.load();
      
      const { redirect_status } = this.$route.query
      if (redirect_status === 'succeeded') {
        this.checkout = true
      }
  },
  
  methods: {

    queueUpdate(value) {
      this.queued = value
      if (!this.queued) this.loadEvents();
    },

    buyTickets(event) {
      this.$router.push("/boxOffice/" + this.boxOffice.path + "/" + event.path)
    },

    async eventTime(event) {
      var eventTime = {};
      var eventTimes = await Api.post(this, "EventTime", "list", event);
      if (eventTimes && eventTimes.length > 0) {
        eventTime.start = eventTimes[0].start;
        eventTime.end = eventTimes[eventTimes.length-1].end;
        eventTime.timezone = eventTimes[0].timezone;
      }
      return eventTime;
    },

    async load() {
      this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice, true);
      if (!this.boxOffice) {
        return;
      }
      var content = this.boxOffice.name
      this.meta = {
        title: this.boxOffice.name,
        meta: [
          { vmid: 'description', name: 'description', content: content },
          { name: "title", property: 'og:title', content: content },
          { name: "description", property: 'og:description', content: content },
          { property: 'twitter:title', content: content },
          { property: 'twitter:description', content: content },
        ]
      };
      this.textColour = this.boxOffice.textColour;
      this.backgroundColour = this.boxOffice.backgroundColour;
      this.loadEvents()
    },

    async loadEvents() {
      this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice, true);
      if (!this.boxOffice) {
        return;
      }
      const events = await this.readEvents();
      this.events = events.sort((a, b) => a.eventTime.start - b.eventTime.start)
      this.loaded = true;
    },

    async readEvents() {
      var all = await Api.post(this, "Event", "listUpcoming", this.boxOffice);
      var eventsAndTimes = []
      for (var item of all) {
        item.eventTime = await this.eventTime(item);
        if (item.eventTime.end > Utils.dateNow()) {
          eventsAndTimes.push(item)
        }
      }
      return eventsAndTimes;
    },
  },

  computed: {
    
    utils: function () {
      return Utils;
    },

    cssVars() {
      const bg_rgb = hexToRgb(this.backgroundColour || '#FFFFFF')
      const text_rgb = hexToRgb(this.textColour || '#000000')
      const artistJS = window.location.href.toLowerCase().includes('johnnyswim')
      const ffTitle = artistJS ? `Glaschu, Oxygen, sans-serif` : `Oxygen, sans-serif`
      const ffText =  artistJS ? `SaaSeriesFD, Oxygen, sans-serif` : `Oxygen, sans-serif`

      return {
        '--text-color': this.textColour,
        '--text-color-rgb': `${text_rgb.r}, ${text_rgb.g}, ${text_rgb.b}`,
        '--background-color': this.backgroundColour,
        '--background-color-rgb': `${bg_rgb.r}, ${bg_rgb.g}, ${bg_rgb.b}`,
        '--font-family-title': ffTitle,
        '--font-family-text': ffText,
        '--button-background': this.textColour,
        '--button-color': this.backgroundColour
      }
    }

  },
}
</script>

<style lang="scss" scoped>

.column, h3, b {
  font-family: var(--font-family-text)
}
.v-card__title, h2, .heading, .price {
  font-family: var(--font-family-title)
}
.v-card__text, .v-card__title {
  word-break: normal;
}
.v-sheet.v-card {
  color: var(--text-color);
  background-color: var(--background-color);
  border-radius: 0px;
}
div {
  color: var(--text-color);
}
.v-btn.v-size--default {
  color: var(--text-color);
}
</style>
