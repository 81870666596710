const defaultVideoParams = {
  url: '', // Required: URL from Cloudinary
  filetype: 'mp4', //default mp4
  res: 260, // Video width,
  scale: 'c_scale' // type of Cloudinary scaling
}
const getCloudinaryVideo = (params) => {
  const nonNullParams = JSON.parse(JSON.stringify({params})).params
  const videoParams = {...defaultVideoParams, ...nonNullParams}
  if (!videoParams.url || videoParams.url.includes('c_scale')) {
    return videoParams.url
  }
  const userUrlArray = videoParams.url.split('/upload/')
  return `${userUrlArray[0]}/upload/${videoParams.scale},w_${videoParams.res}/${userUrlArray[1].split('.')[0]}.${videoParams.filetype}`
}

const defaultImageParams = {
  url: '', // Required: URL from Cloudinary
  width: 260, // Image width
  height: null
}
const getCloudinaryImage = (params) => {
  const nonNullParams = JSON.parse(JSON.stringify({params})).params
  const imageParams = {...defaultImageParams, ...nonNullParams}
  if (!imageParams.url || imageParams.url.includes('c_lfill')) {
    return imageParams.url
  }
  if (!imageParams.url.startsWith("https://res.cloudinary.com/")) return imageParams.url
  const userUrlArray = imageParams.url.split('/upload/')
  return `${userUrlArray[0]}/upload/c_lfill,w_${imageParams.width}${imageParams.height ? `,h_${imageParams.height}` : ''},f_auto,q_auto,dpr_2.0/${userUrlArray[1]}`
}

export { getCloudinaryVideo, getCloudinaryImage }